import { gql } from 'graphql-request';

export const GET_BLOCK_EVENTS = gql`
  query getBlockEvent($fromDate: date!, $toDate: date!) {
    blockEvent(where: { scheduledDate: { _gte: $fromDate, _lte: $toDate } }) {
      id
      blockId
      scheduledDate
      scheduledTime
      duration
      timezone
      gmtOffset
    }
  }
`;

export const INSERT_BLOCK_EVENT = gql`
  mutation insertBlockEvent(
    $id: uuid!
    $blockId: uuid!
    $scheduledDate: date!
    $scheduledTime: time!
    $duration: interval!
    $timezone: String!
    $gmtOffset: String!
  ) {
    insertBlockEventOne(
      object: {
        id: $id
        blockId: $blockId
        scheduledDate: $scheduledDate
        scheduledTime: $scheduledTime
        duration: $duration
        timezone: $timezone
        gmtOffset: $gmtOffset
      }
    ) {
      id
    }
  }
`;

export const UPDATE_BLOCK_EVENT = gql`
  mutation updateBlockEvent(
    $id: uuid!
    $scheduledDate: date
    $scheduledTime: time
    $duration: interval
    $timezone: String
    $gmtOffset: String!
  ) {
    updateBlockEventByPk(
      _set: {
        scheduledDate: $scheduledDate
        scheduledTime: $scheduledTime
        duration: $duration
        timezone: $timezone
        gmtOffset: $gmtOffset
      }
      pkColumns: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_BLOCK_EVENT = gql`
  mutation deleteBlockEvent($id: uuid!) {
    deleteBlockEventByPk(id: $id) {
      id
    }
  }
`;
