import { COMPLETE } from '@/constants/action';
import { useBlocks } from '@/services/block/hooks';
import { useBlockEvents } from '@/services/blockEvent/hooks';
import { useCategories } from '@/services/categories/hooks';
import { useCategoryEvents } from '@/services/categoryEvent/hooks';
import { useCronofyProfiles } from '@/services/cronofy/hooks';
import { useExternalEvents } from '@/services/events/hooks';
import { useDailyPlanActionEvents } from '@/services/myPlan/hooks';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { getDurationInMinutes } from '@/utils/action';
import {
  blockEventAdapterApi,
  calendarAdapterApi,
  categoryEventAdapterApi,
  checkIfIsAllDay,
  endOfTheWeek,
  externalCalendarMyEventAdapterApi,
  startOfTheWeek,
  utcToLocalDate,
} from '@/utils/calendar';
import { useMapBy } from '@/utils/useMapBy';
import { differenceInMinutes, endOfDay, isSameDay, startOfDay } from 'date-fns';
import { ReactNode, useCallback, useMemo } from 'react';

import { CalendarContext } from './context';

type CalendarProviderProps = {
  children: ReactNode;
  date: Date;
  view: string;
};

function CalendarProvider({ children, date, view }: CalendarProviderProps) {
  const startDate = date && startOfTheWeek(date);
  const endDate = date && endOfTheWeek(date);

  const { data: weeklyPlanId } = useWeeklyPlanId(date);
  const { data: cronofyProfiles } = useCronofyProfiles();
  const { data: actionEvents } = useDailyPlanActionEvents(weeklyPlanId, date, view);
  const { data: externalEvents } = useExternalEvents(startDate, endDate);
  const { data: categories } = useCategories();
  const { data: blocks } = useBlocks();
  const categoriesMap = useMapBy(categories?.category || [], 'id');
  const blocksMap = useMapBy(blocks?.block || [], 'id');
  const { data: categoryEvents } = useCategoryEvents(startDate, endDate);
  const { data: blockEvents } = useBlockEvents(startDate, endDate);

  const makeHeaderDuration = useCallback(
    (day: Date) => {
      // TODO: this is a duplicate of makeEvents function in utils/myplan.ts, fix
      let starredEventsDuration = 0;
      let scheduledEventsDuration = 0;
      let plannedEventsDuration = 0;
      let externalEventsDuration = 0;

      // Go through all categories.
      if (actionEvents) {
        for (let actionEvent of actionEvents) {
          const isActionNotScheduled =
            (view === 'day' && !actionEvent.action.scheduledTime) ||
            (view === 'week' && !actionEvent.action.scheduledDate);
          if (actionEvent.action.progressStatus === COMPLETE || isActionNotScheduled) {
            continue;
          }

          const actionDate = utcToLocalDate(
            actionEvent.action.scheduledDate ?? '',
            actionEvent.action.scheduledTime,
            actionEvent.action.timezone,
            actionEvent.action.gmtOffset,
          );

          if (!isSameDay(day, actionDate)) {
            continue;
          }

          // Check sum starred actions in the day calendar.
          if (actionEvent.action.dateOfStarring) {
            starredEventsDuration += getDurationInMinutes(actionEvent.action);
          }

          scheduledEventsDuration += getDurationInMinutes(actionEvent.action);
        }
      }

      // Include external event durations as part of total duration
      if (externalEvents) {
        for (let event of externalEvents) {
          const start = new Date(event.start);
          const end = new Date(event.end);

          const isInternal = actionEvents.find((e) => e.action.title === event.summary);

          // Cronofy sends all events for the matched days, even outside the queried timeframe.
          // You may receive events that start hours before/after the start/end query time, so
          // we filter those events out of the query range.
          if (
            start >= startOfDay(day) &&
            start <= endOfDay(day) &&
            !checkIfIsAllDay(event.start, event.end) &&
            !isInternal
          ) {
            const duration = differenceInMinutes(end, start);
            externalEventsDuration += duration;
          }
        }
      }

      // sum actions and external event durations.
      const scheduledEventsWithExternalEventsDuration = scheduledEventsDuration + externalEventsDuration;

      return {
        starredEventsDuration,
        scheduledEventsDuration,
        externalEventsDuration,
        scheduledEventsWithExternalEventsDuration,
        plannedEventsDuration,
      };
    },
    [actionEvents, externalEvents, view],
  );

  const combinedEvents = useMemo(() => {
    // Convert external events into Calendar data
    const formattedExternalEvents = externalCalendarMyEventAdapterApi(externalEvents, cronofyProfiles);

    // Convert action events into Calendar data
    const formattedTodayActionEvents = actionEvents.map((actionEvent) => calendarAdapterApi(actionEvent.action));

    const formattedCategoryEvents =
      categoryEvents?.categoryEvent.map((categoryEvent) =>
        categoryEventAdapterApi(categoryEvent, categoriesMap.get(categoryEvent.categoryId)),
      ) ?? [];

    const formattedBlockEvents =
      blockEvents?.blockEvent.map((blockEvent) =>
        blockEventAdapterApi(blockEvent, blocksMap.get(blockEvent.blockId)),
      ) ?? [];

    return [
      ...formattedTodayActionEvents,
      ...formattedCategoryEvents,
      ...formattedBlockEvents,
      ...formattedExternalEvents,
    ];
  }, [
    externalEvents,
    cronofyProfiles,
    actionEvents,
    categoryEvents?.categoryEvent,
    blockEvents?.blockEvent,
    categoriesMap,
    blocksMap,
  ]);

  return (
    <CalendarContext.Provider
      value={{
        actionEvents,
        categoryEvents: categoryEvents?.categoryEvent,
        blockEvents: blockEvents?.blockEvent,
        combinedEvents,
        makeHeaderDuration,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export default CalendarProvider;
