import { gql } from 'graphql-request';

export const GET_CATEGORY_EVENTS = gql`
  query getCategoryEvent($fromDate: date!, $toDate: date!) {
    categoryEvent(where: { scheduledDate: { _gte: $fromDate, _lte: $toDate } }) {
      id
      categoryId
      scheduledDate
      scheduledTime
      duration
      timezone
      gmtOffset
    }
  }
`;

export const INSERT_CATEGORY_EVENT = gql`
  mutation insertCategoryEvent(
    $id: uuid!
    $categoryId: uuid!
    $scheduledDate: date!
    $scheduledTime: time!
    $duration: interval!
    $timezone: String!
    $gmtOffset: String!
  ) {
    insertCategoryEventOne(
      object: {
        id: $id
        categoryId: $categoryId
        scheduledDate: $scheduledDate
        scheduledTime: $scheduledTime
        duration: $duration
        timezone: $timezone
        gmtOffset: $gmtOffset
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CATEGORY_EVENT = gql`
  mutation updateCategoryEvent(
    $id: uuid!
    $scheduledDate: date
    $scheduledTime: time
    $duration: interval
    $timezone: String
    $gmtOffset: String
  ) {
    updateCategoryEventByPk(
      _set: {
        scheduledDate: $scheduledDate
        scheduledTime: $scheduledTime
        duration: $duration
        timezone: $timezone
        gmtOffset: $gmtOffset
      }
      pkColumns: { id: $id }
    ) {
      id
    }
  }
`;

export const DELETE_CATEGORY_EVENT = gql`
  mutation deleteCategoryEvent($id: uuid!) {
    deleteCategoryEventByPk(id: $id) {
      id
    }
  }
`;
