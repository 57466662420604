import ConfirmRepeatActionModal from '@/components/ConfirmRepeatActionModal';
import DynamicSegmentedButton from '@/components/DynamicSegmentedButton';
import StyledModal from '@/components/StyledModal';
import { WEEKDAYS } from '@/constants/calendar';
import { INSERT_ACTIONS } from '@/gql/actions';
import { CreateActionsResponse, QuickCreateActionPayload } from '@/gql/actions/types';
import { CREATE_EVENTS } from '@/gql/events';
import { CreateEventsPayload, CreateEventsResponse } from '@/gql/events/types';
import { GET_OR_CREATE_WEEKLY_PLANS_BATCH } from '@/gql/weeklyPlan';
import { GetOrCreateWeeklyPlansResponse } from '@/gql/weeklyPlan/types';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { IconChevronDown } from '@/theme/icons';
import { Action } from '@/types/actions';
import { addActionToCache, createActionDuplicate } from '@/utils/action';
import {
  formatDateToString,
  getRepeatDatesFromSchedule,
  getWeekBoundariesForDates,
  startOfTheWeek,
  utcToLocalDate,
} from '@/utils/calendar';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const repeatTimesOptions = Array(21)
  .fill(0)
  .map((x, i) => i)
  .slice(1);

const intervalTypeOptions = [
  {
    label: 'Day',
    value: 0,
  },
  {
    label: 'Week',
    value: 1,
  },
];

export type DuplicateActionScheduleType = {
  repeatTimes: number;
  intervalType: number;
  onWeekdays: string[];
};

type Props = {
  action: Action;
  isOpen: boolean;
  onCloseDuplicateActionModal: () => void;
};

const DuplicateActionModal = ({ action, isOpen, onCloseDuplicateActionModal }: Props) => {
  const [isConfirmRepeatModal, setIsConfirmRepeatModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const actionDayOfWeekIndex = useMemo(() => {
    if (action.scheduledDate)
      return utcToLocalDate(action.scheduledDate, action.scheduledTime, action.timezone, action.gmtOffset).getDay();
    return 0;
  }, [action.scheduledDate, action.scheduledTime]);

  const actionDayOfWeek = useMemo(() => WEEKDAYS[actionDayOfWeekIndex], [actionDayOfWeekIndex]);

  const defaultRepeatSchedule = useMemo(
    () => ({
      repeatTimes: 1,
      intervalType: 0,
      onWeekdays: [actionDayOfWeek],
    }),
    [actionDayOfWeek],
  );

  const [repeatSchedule, setRepeatSchedule] = useState<DuplicateActionScheduleType>(defaultRepeatSchedule);

  const toast = useToast();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  useEffect(() => {
    if (repeatSchedule.intervalType === 0) {
      setRepeatSchedule((schedule) => ({ ...schedule, onWeekdays: [actionDayOfWeek] }));
    }
  }, [repeatSchedule.intervalType, actionDayOfWeek]);

  useEffect(() => {
    setRepeatSchedule((schedule) => ({ ...schedule, onWeekdays: [actionDayOfWeek] }));
  }, [actionDayOfWeek]);

  const handleCloseConfirmRepeatModal = useCallback(() => {
    setIsConfirmRepeatModal(false);
  }, [setIsConfirmRepeatModal]);

  const syncWithCronofy = useCallback(
    (actionId: string) => {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          cronofySyncEvent({ eventId: actionId, eventType: 'Action' });
          resolve();
        }, 100);
      });
    },
    [cronofySyncEvent],
  );

  const handleDuplicateAction = useCallback(async () => {
    if (repeatSchedule && action?.scheduledDate) {
      setIsLoading(true);
      const repeatOnDates = getRepeatDatesFromSchedule(
        repeatSchedule,
        action.scheduledDate,
        action.scheduledTime,
        action.timezone,
        action.gmtOffset,
      );
      const weekBoundariesForDates: [Date, Date][] = getWeekBoundariesForDates(repeatOnDates);

      try {
        const weeklyPlans: Record<string, string> = {};
        const weeklyPlansData = await fetchData<GetOrCreateWeeklyPlansResponse>(GET_OR_CREATE_WEEKLY_PLANS_BATCH, {
          objects: weekBoundariesForDates.map(([dateFrom, dateTo]) => ({
            dateFrom: formatDateToString(dateFrom),
            dateTo: formatDateToString(dateTo),
          })),
        });
        weeklyPlansData.getOrCreateWeeklyPlans.plans.forEach((weeklyPlan) => {
          weeklyPlans[weeklyPlan.dateFrom] = weeklyPlan.id;
        });

        const actions = repeatOnDates
          .map((dateStruct) => {
            const date = utcToLocalDate(
              dateStruct.scheduledDate,
              action.scheduledTime,
              action.timezone,
              action.gmtOffset,
            );
            const weeklyPlanId = weeklyPlans[formatDateToString(startOfTheWeek(date))];
            if (!weeklyPlanId) {
              console.error('Weekly plan ID not found for date', dateStruct);
              return;
            }
            return createActionDuplicate(action, dateStruct.scheduledDate, action.scheduledTime, weeklyPlanId);
          })
          .filter((action) => !!action);

        const insertActionsData = await fetchData<CreateActionsResponse>(INSERT_ACTIONS, {
          objects: actions as QuickCreateActionPayload[],
        });
        const eventsPayload: CreateEventsPayload = { objects: [] };
        for (const action of insertActionsData.insertAction.returning) {
          if (action.scheduledDate) {
            eventsPayload.objects.push({
              id: uuidv4(),
              actionId: action.id,
            });
          }
        }
        const insertEventsData = await fetchData<CreateEventsResponse>(CREATE_EVENTS, eventsPayload);
        insertActionsData.insertAction.returning.forEach(async (action) => {
          const actionEvent = insertEventsData.insertEvent.returning.find((event) => event.action.id === action.id);
          if (actionEvent) {
            action.event = {
              id: actionEvent.id,
              typeName: 'Event',
            };
          }
          addActionToCache(action);
        });

        for (const action of insertActionsData.insertAction.returning) {
          if (action.scheduledDate && action.scheduledTime) await syncWithCronofy(action.id);
        }

        toast({
          title: 'Repeated actions created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setRepeatSchedule(defaultRepeatSchedule);
        onCloseDuplicateActionModal();
      } catch (error) {
        console.error(error);
        toast({
          title: 'Error duplicating actions',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
  }, [action, defaultRepeatSchedule, repeatSchedule, toast, syncWithCronofy, onCloseDuplicateActionModal]);

  const handleWeekdayClick = (weekday: string) => {
    if (repeatSchedule.onWeekdays.includes(weekday)) {
      setRepeatSchedule((schedule) => ({
        ...schedule,
        onWeekdays: schedule.onWeekdays.filter((day) => day !== weekday),
      }));
    } else {
      setRepeatSchedule((schedule) => ({ ...schedule, onWeekdays: [...schedule.onWeekdays, weekday] }));
    }
  };

  // at least 1 day of the week must be selected
  const canUncheckWeekday = repeatSchedule?.onWeekdays.length > 1;

  return (
    <>
      <StyledModal isOpen={isOpen} onClose={onCloseDuplicateActionModal} title="Duplicate Action">
        <ModalBody minHeight={rem(240)} paddingTop={rem(16)}>
          <VStack alignItems="stretch" justifyContent="space-between" gap={rem(12)}>
            <HStack alignItems="baseline">
              <Text flexBasis={rem(78)}>Duplicate</Text>
              <Box>
                <DynamicSegmentedButton
                  options={intervalTypeOptions}
                  onChange={(value: number) => setRepeatSchedule((schedule) => ({ ...schedule, intervalType: value }))}
                  layoutId="repeat-interval-type"
                  startIndex={repeatSchedule.intervalType}
                  buttonPaddingX={rem(48)}
                  fullWidth
                />
              </Box>
            </HStack>

            {repeatSchedule.intervalType === 1 && (
              <>
                <Divider opacity="1" borderColor="whiteAlpha.300" marginY={rem(16)} />
                <HStack gap={rem(8)}>
                  <Text flex="0" flexBasis={rem(78)}>
                    On
                  </Text>
                  <HStack gap={rem(8)}>
                    {[...WEEKDAYS.slice(1), WEEKDAYS[0]].map((day) => (
                      <Box key={day}>
                        <Button
                          width={rem(36)}
                          height={rem(36)}
                          padding={0}
                          textAlign="center"
                          textTransform="uppercase"
                          borderRadius={rem(36)}
                          isDisabled={!canUncheckWeekday && repeatSchedule.onWeekdays.includes(day)}
                          onClick={() => handleWeekdayClick(day)}
                          size="sm"
                          variant={repeatSchedule.onWeekdays.includes(day) ? 'primary' : 'secondary'}
                        >
                          {day.slice(0, 1)}
                        </Button>
                      </Box>
                    ))}
                  </HStack>
                </HStack>
              </>
            )}

            <Divider opacity="1" borderColor="whiteAlpha.300" marginY={rem(16)} />

            <HStack zIndex={5} gap={rem(8)}>
              <Text flexBasis={rem(78)}>End after</Text>
              <Box flex="0">
                <Menu>
                  <MenuButton as={Button} rightIcon={<Icon as={IconChevronDown} />} size="sm" variant="secondary">
                    {repeatSchedule.repeatTimes}
                  </MenuButton>
                  <MenuList overflowY="scroll" maxHeight={rem(240)} backgroundColor="background-primary">
                    {repeatTimesOptions.map((repeatTimesValue) => (
                      <MenuItem
                        key={repeatTimesValue}
                        fontSize="sm"
                        _hover={{ backgroundColor: 'background-secondary' }}
                        backgroundColor="transparent"
                        onClick={() =>
                          setRepeatSchedule((schedule) => ({ ...schedule, repeatTimes: repeatTimesValue }))
                        }
                      >
                        {repeatTimesValue}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
              <Text>times</Text>
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="flex-end" gap={rem(16)} display="flex">
          <HStack gap={rem(16)}>
            <Button onClick={onCloseDuplicateActionModal} size="lg" variant="secondary">
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={() => setIsConfirmRepeatModal(true)}
              size="lg"
              type="submit"
              variant="primary"
            >
              Duplicate Action
            </Button>
          </HStack>
        </ModalFooter>
      </StyledModal>

      {isConfirmRepeatModal && (
        <ConfirmRepeatActionModal
          repeatTimes={repeatSchedule?.repeatTimes ?? 0}
          isOpen={isConfirmRepeatModal}
          onCloseConfirmRepeatActionModal={handleCloseConfirmRepeatModal}
          onConfirm={() => {
            handleDuplicateAction();
            handleCloseConfirmRepeatModal();
          }}
        />
      )}
    </>
  );
};

export default DuplicateActionModal;
