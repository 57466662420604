import { gql } from 'graphql-request';

export const UPDATE_ACTIONS_CATEGORY_ORDER = gql`
  mutation updateActionsCategoryOrder($actionUpdates: [ActionUpdates!]!) {
    updateActionMany(updates: $actionUpdates) {
      returning {
        id
        categoryOrder
      }
    }
  }
`;

export const UPDATE_BLOCK_ACTIONS_ORDER = gql`
  mutation updateBlockActionsOrder($actionUpdates: [ActionUpdates!]!) {
    updateActionMany(updates: $actionUpdates) {
      returning {
        id
        blockOrder
      }
    }
  }
`;

export const REMOVE_ACTION_FROM_BLOCK = gql`
  mutation removeActionFromBlock($actionId: uuid!) {
    updateActionByPk(_set: { blockId: null, blockOrder: null }, pkColumns: { id: $actionId }) {
      id
    }
  }
`;

export const ADD_ACTION_TO_BLOCK = gql`
  mutation addActionToBlock($actionId: uuid!, $blockId: uuid!, $blockOrder: smallint!, $projectId: uuid) {
    updateActionByPk(
      _set: { blockId: $blockId, blockOrder: $blockOrder, projectId: $projectId }
      pkColumns: { id: $actionId }
    ) {
      id
    }

    updateBlockByPk(_set: { isCompleted: false }, pkColumns: { id: $blockId }) {
      id
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent(
    $id: uuid
    $actionId: uuid!
    $scheduledDate: date
    $scheduledTime: time
    $timezone: String
    $gmtOffset: String
  ) {
    insertEventOne(object: { id: $id, actionId: $actionId }) {
      id
      actionId
      action {
        event {
          id
        }
      }
    }

    updateActionByPk(
      _set: { scheduledDate: $scheduledDate, scheduledTime: $scheduledTime, timezone: $timezone, gmtOffset: $gmtOffset }
      pkColumns: { id: $actionId }
    ) {
      id
    }
  }
`;

export const DELETE_EVENT_AND_UPDATE_ACTION = gql`
  mutation deleteEvent($eventId: uuid!, $actionId: uuid!, $scheduledDate: date, $categoryOrder: smallint) {
    deleteEventByPk(id: $eventId) {
      id
    }

    updateActionByPk(
      _set: {
        scheduledDate: $scheduledDate
        scheduledTime: null
        categoryOrder: $categoryOrder
        blockId: null
        blockOrder: null
      }
      pkColumns: { id: $actionId }
    ) {
      id
    }
  }
`;

export const DELETE_EVENT_AND_UPDATE_BLOCK_ACTION = gql`
  mutation deleteEvent($eventId: uuid!, $actionId: uuid!, $scheduledDate: date, $blockId: uuid, $blockOrder: smallint) {
    deleteEventByPk(id: $eventId) {
      id
    }

    updateActionByPk(
      _set: { scheduledDate: $scheduledDate, scheduledTime: null, blockId: $blockId, blockOrder: $blockOrder }
      pkColumns: { id: $actionId }
    ) {
      id
      block {
        id
      }
      event {
        id
      }
    }
  }
`;

export const COMPLETE_BLOCK = gql`
  mutation completeBlock($id: uuid!) {
    updateBlockByPk(_set: { isCompleted: true }, pkColumns: { id: $id }) {
      id
      isCompleted
    }
  }
`;

export const INCOMPLETE_BLOCK = gql`
  mutation completeBlock($id: uuid!) {
    updateBlockByPk(_set: { isCompleted: false }, pkColumns: { id: $id }) {
      id
      isCompleted
    }
  }
`;

export const DELETE_BLOCK = gql`
  mutation deleteBlockAndMoveActions($blockId: uuid!) {
    deleteBlockPreserveActions(blockId: $blockId) {
      ok
    }
  }
`;
