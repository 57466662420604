import { SIGN_IN_URL } from '@/services/user/hooks';
import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';
import mem from 'mem';

export const graphQLClient = new GraphQLClient(import.meta.env.VITE_GRAPHQL_API_URL, {
  credentials: 'include',
});

async function regenerateCookie(): Promise<boolean> {
  try {
    const url = new URL('/auth/cookie/userinfo', import.meta.env.VITE_RRI_AUTH_URL);
    const response = await fetch(url, { credentials: 'include' });

    if (response.ok) {
      console.log('🔄 Cookie successfully regenerated');
      return true;
    } else {
      console.error('⚠️ Error regenerating the cookie');
      return false;
    }
  } catch (error) {
    console.error('❌ Error trying to regenerate the cookie:', error);
    return false;
  }
}

const memoizedRegenerateCookie = mem(regenerateCookie, {
  maxAge: 5000, // Cache duration in milliseconds (5 seconds here)
});

function isAuthOrCookieError(error: any): boolean {
  return error?.response?.errors?.some(
    (err: any) =>
      ['invalid-headers', 'invalid-jwt'].includes(err?.extensions?.code) ||
      ['Auth token is not valid!', 'You do not have permission to perform this action'].includes(err?.message) ||
      err?.message.includes('mutation_root') ||
      err?.message.includes('query_root'),
  );
}

async function retryRequest<T>(query: RequestDocument, variables?: Variables): Promise<T> {
  const success = await memoizedRegenerateCookie();
  if (success) {
    try {
      return await graphQLClient.request<T>(query, variables);
    } catch (retryError: any) {
      if (isAuthOrCookieError(retryError)) {
        console.log('🔒 Session is still invalid after regenerating the cookie, redirecting to sign-in page');
        window.location.assign(SIGN_IN_URL);
      }
      throw retryError;
    }
  } else {
    console.log('🔒 Failed to regenerate the cookie, redirecting to sign-in page');
    window.location.assign(SIGN_IN_URL);
    throw new Error('Cookie regeneration failed');
  }
}

export async function fetchData<T = any>(query: RequestDocument, variables?: Variables): Promise<T> {
  try {
    return await graphQLClient.request<T>(query, variables);
  } catch (error: any) {
    if (isAuthOrCookieError(error)) {
      console.log('🔒 Missing `id` cookie or session expired, attempting to regenerate cookie...');
      return retryRequest(query, variables);
    }

    throw error;
  }
}
