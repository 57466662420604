import { GetBlocksResponse } from '@/gql/block/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import {
  ADD_ACTION_TO_BLOCK,
  COMPLETE_BLOCK,
  CREATE_EVENT,
  DELETE_BLOCK,
  DELETE_EVENT_AND_UPDATE_ACTION,
  DELETE_EVENT_AND_UPDATE_BLOCK_ACTION,
  INCOMPLETE_BLOCK,
  REMOVE_ACTION_FROM_BLOCK,
  UPDATE_ACTIONS_CATEGORY_ORDER,
  UPDATE_BLOCK_ACTIONS_ORDER,
} from '@/gql/myPlan';
import {
  AddActionToBlockPayload,
  AddActionToBlockResponse,
  CompleteBlockPayload,
  CompleteBlockResponse,
  CreateEventPayload,
  CreateEventResponse,
  DeleteBlockPayload,
  DeleteBlockResponse,
  DeleteEventAndUpdateBlockActionPayload,
  DeleteEventPayload,
  DeleteEventResponse,
  IncompleteBlockPayload,
  IncompleteBlockResponse,
  RemoveActionFromBlockPayload,
  RemoveActionFromBlockResponse,
  UpdateActionsCategoryOrderPayload,
  UpdateActionsCategoryOrderResponse,
  UpdateBlockActionsBlockOrderPayload,
  UpdateBlockActionsBlockOrderResponse,
} from '@/gql/myPlan/types';
import { useActions, useUpdateActionStatus } from '@/services/action/hooks';
import { useBlocks } from '@/services/block/hooks';
import { useCategories } from '@/services/categories/hooks';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import {
  addActionToBlockInProjectCache,
  deleteBlockFromProjectCache,
  removeActionFromTheBlockOnCache,
  removeIncompleteActionsFromBlockInProjectCache,
  updateBlockActionsOrderOnCache,
} from '@/services/project/hooks';
import { getSelectedDate } from '@/stores/useCalendar';
import { Action, ActionResponse, isPlannedAction } from '@/types/actions';
import { Block } from '@/types/block';
import { ActionEventType } from '@/types/myPlan';
import { addActionToCache as addAction, getActionById, updateAction } from '@/utils/action';
import { deleteBlockCache, getBlockById } from '@/utils/block';
import { formatDateToString, utcToLocalDate } from '@/utils/calendar';
import { getMonthlyCalendarQueryKey } from '@/utils/query';
import { trackActionAddedToBlock } from '@/utils/tracking';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { orderBy, pick } from 'lodash';
import { useMemo } from 'react';

export const useActionsBlocksAndEvents = (selectedDate?: Date) => {
  const { data: actions, ...actionsQueryState } = useActions({}, selectedDate);
  const { data: blocks, ...blocksQueryState } = useBlocks();

  const data = useMemo(
    () => ({
      action:
        actions?.action.map((action) => ({
          ...action,
          typeName: 'Action',
        })) ?? [],
      block: blocks?.block ?? [],
    }),
    [actions, blocks],
  );

  return {
    isLoading: actionsQueryState.isLoading || blocksQueryState.isLoading,
    isError: actionsQueryState.isError || blocksQueryState.isError,
    error: actionsQueryState.error || blocksQueryState.error,
    data,
  };
};

export const useWeeklyPlanActionEvents = (selectedDate?: Date) => {
  const { data: myPlanData, ...queryState } = useActionsBlocksAndEvents(selectedDate);

  const { data: categoriesList } = useCategories();

  const actionEvents = useMemo(() => {
    const actionEvents: ActionEventType[] = [];

    if (!myPlanData) return actionEvents;

    const categories = categoriesList?.category ?? [];

    // Go through all categories.
    for (let category of categories) {
      for (let action of myPlanData.action) {
        // Skip actions that are not planned, as we are interested only in
        // actions with an event
        if (!isPlannedAction(action)) continue;
        if (action?.categoryId === category?.id && action?.event?.id) {
          actionEvents.push({
            id: action.event.id,
            action: { ...action, typeName: 'Action' },
            actionId: action.id,
            typeName: 'Event',
          });
        }
      }
    }
    return actionEvents;
  }, [categoriesList?.category, myPlanData]);

  return {
    ...queryState,
    data: actionEvents,
  };
};

export const useDailyPlanActionEvents = (weeklyPlanId: string | undefined, selectedDate: Date, view: string) => {
  const { data: weeklyPlanActionEvents, ...queryState } = useWeeklyPlanActionEvents(selectedDate);

  const dailyActionEvents = useMemo(
    () =>
      weeklyPlanActionEvents.filter((a) => {
        const eventDate = utcToLocalDate(
          a.action.scheduledDate,
          a.action.scheduledTime,
          a.action.timezone,
          a.action.gmtOffset,
        );
        return formatDateToString(eventDate) === formatDateToString(selectedDate) && a.action.scheduledTime !== null;
      }),
    [weeklyPlanActionEvents, selectedDate],
  );

  return {
    ...queryState,
    data: view === 'day' ? dailyActionEvents : weeklyPlanActionEvents,
  };
};

type UpdateActionsOrderDTO = {
  actionId: string;
  categoryOrder: number;
};

export const useUpdateActionsCategoryOrder = (
  options?: Omit<
    UseMutationOptions<UpdateActionsCategoryOrderResponse, APIError, UpdateActionsCategoryOrderPayload[]>,
    'onMutate'
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateActionsCategoryOrderPayload[]) =>
      fetchData<UpdateActionsCategoryOrderResponse>(UPDATE_ACTIONS_CATEGORY_ORDER, { actionUpdates }),

    onMutate: (variables) => {
      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      if (!weeklyPlanId) {
        return;
      }

      let actions: UpdateActionsOrderDTO[] = [];

      variables.map(({ where, _set }) => {
        actions = [
          ...actions,
          {
            actionId: String(where.id._eq),
            categoryOrder: Number(_set.categoryOrder),
          },
        ];
      });

      queryClient.setQueryData<ActionResponse>(keys.actions.all._ctx.week(weeklyPlanId).queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          action: orderBy(
            state.action.map((action) => {
              const updatedAction = actions.find((a) => a.actionId === action.id);

              if (updatedAction) {
                return {
                  ...action,
                  categoryOrder: updatedAction.categoryOrder,
                };
              }

              return action;
            }),
            'categoryOrder',
            'asc',
          ),
        };
      });
    },

    ...options,
  });
};

type UpdateBlockActionsOrderDTO = {
  actionId: string;
  blockOrder: number;
};

export const useUpdateBlockActionsBlockOrder = (
  options?: Omit<
    UseMutationOptions<UpdateBlockActionsBlockOrderResponse, APIError, UpdateBlockActionsBlockOrderPayload>,
    'onMutate'
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateBlockActionsBlockOrderPayload) =>
      fetchData<UpdateBlockActionsBlockOrderResponse>(UPDATE_BLOCK_ACTIONS_ORDER, {
        actionUpdates: actionUpdates.updates,
      }),

    onMutate: (variables) => {
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      let actions: UpdateBlockActionsOrderDTO[] = [];

      variables.updates.map(({ where, _set }) => {
        actions = [
          ...actions,
          {
            actionId: String(where.id._eq),
            blockOrder: Number(_set.blockOrder),
          },
        ];
      });

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      variables.categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(variables.categoryId).queryKey);

      cacheKeys.forEach((cache) =>
        queryClient.setQueryData<ActionResponse>(cache, (state) => {
          if (state == null) {
            return state;
          }

          return {
            action: state.action.map((action) => {
              const updatedAction = actions.find((a) => a.actionId === action.id);

              if (updatedAction) {
                return {
                  ...action,
                  blockOrder: updatedAction.blockOrder,
                };
              }

              return action;
            }),
          };
        }),
      );

      if (variables.projectId) {
        updateBlockActionsOrderOnCache(variables.updates, variables.projectId);
      }
    },

    ...options,
  });
};

export const useRemoveActionFromBlock = (
  options?: Omit<UseMutationOptions<RemoveActionFromBlockResponse, APIError, RemoveActionFromBlockPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: RemoveActionFromBlockPayload) =>
      fetchData<RemoveActionFromBlockResponse>(REMOVE_ACTION_FROM_BLOCK, pick(payload, ['actionId'])),

    onMutate: ({ actionId, categoryId, projectId }) => {
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);

      cacheKeys.forEach((cache) =>
        queryClient.setQueryData<ActionResponse>(cache, (state) => {
          if (state == null) {
            return state;
          }

          return {
            action: state.action.map((action) => {
              if (action.id === actionId) {
                return {
                  ...action,
                  blockId: null,
                  blockOrder: null,
                };
              }

              return action;
            }),
          };
        }),
      );

      if (projectId) {
        removeActionFromTheBlockOnCache(actionId, projectId);
      }
    },

    ...options,
  });
};

export const useAddActionToBlock = (
  options?: Omit<UseMutationOptions<AddActionToBlockResponse, APIError, AddActionToBlockPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: AddActionToBlockPayload) =>
      fetchData<AddActionToBlockResponse>(
        ADD_ACTION_TO_BLOCK,
        pick(payload, ['actionId', 'blockId', 'blockOrder', 'projectId']),
      ),

    onMutate: ({ actionId, blockId, blockOrder, projectId, categoryId }) => {
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const blocks = queryClient.getQueryData<GetBlocksResponse>(keys.blocks.all.queryKey);
      const block = getBlockById(blocks?.block ?? [], blockId);

      // If the block is completed, then set it to incomplete
      if (block?.isCompleted) {
        onMutateBlockStatus(blockId, false);
      }

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);
      const actionsTracked: string[] = [];

      cacheKeys.forEach((cache) =>
        queryClient.setQueryData<ActionResponse>(cache, (state) => {
          if (state == null) {
            return state;
          }

          return {
            action: state.action.map((action) => {
              if (action.id === actionId) {
                if (!actionsTracked.includes(actionId)) {
                  trackActionAddedToBlock(!!action.dateOfStarring);
                  actionsTracked.push(actionId);
                }

                return {
                  ...action,
                  blockId,
                  blockOrder,
                  projectId,
                  project: block?.project,
                };
              }

              return action;
            }),
          };
        }),
      );

      if (projectId) {
        addActionToBlockInProjectCache({ actionId, blockId, blockOrder }, projectId);
      }
    },

    ...options,
  });
};

export const addEventToCache = (event: ActionEventType) => {
  const selectedDate = getSelectedDate();

  if (!selectedDate) {
    return;
  }

  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  if (!weeklyPlanId) {
    return;
  }

  updateAction({
    ...event.action,
    scheduledDate: event.action?.scheduledDate,
    scheduledTime: event.action?.scheduledTime,
    timezone: event.action?.timezone,
    event: {
      id: event.id,
      typeName: 'Event',
    },
  });
};

export const useCreateEvent = (
  options?: Omit<UseMutationOptions<CreateEventResponse, APIError, CreateEventPayload>, 'onMutate' | 'onSettled'>,
) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: CreateEventPayload) => fetchData<CreateEventResponse>(CREATE_EVENT, payload),

    onMutate: ({ id: eventId, actionId, scheduledDate, scheduledTime, timezone, gmtOffset }) => {
      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      if (!weeklyPlanId) {
        return;
      }

      const actions = queryClient.getQueryData<ActionResponse>(keys.actions.all._ctx.week(weeklyPlanId).queryKey);

      const action = getActionById(actions?.action ?? [], actionId);

      if (!action) {
        return;
      }

      addEventToCache({
        id: eventId,
        actionId,
        action: {
          ...action,
          event: { id: eventId, typeName: 'Event' },
          scheduledDate,
          scheduledTime,
          timezone: timezone ?? '',
          typeName: 'Action',
          gmtOffset: gmtOffset ?? '',
        },
        typeName: 'Event',
      });
    },
    ...options,
    onSuccess: (data, variables, context) => {
      cronofySyncEvent({ eventId: variables.actionId, eventType: 'Action' });
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteEventAndUpdateAction = (
  options?: Omit<UseMutationOptions<DeleteEventResponse, APIError, DeleteEventPayload>, 'onMutate' | 'onSettled'>,
) => {
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: DeleteEventPayload) =>
      fetchData<DeleteEventResponse>(DELETE_EVENT_AND_UPDATE_ACTION, payload),

    onMutate: ({ actionId, categoryOrder, scheduledDate }) => {
      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      cacheKeys.push(getMonthlyCalendarQueryKey());

      cacheKeys.forEach((cache) =>
        queryClient.setQueryData<ActionResponse>(cache, (state) => {
          if (state == null) {
            return state;
          }

          return {
            action: state.action.map((action) => {
              if (action.id === actionId) {
                return {
                  ...action,
                  categoryOrder,
                  scheduledDate,
                  scheduledTime: null,
                  event: null,
                };
              }

              return action;
            }),
          };
        }),
      );
    },

    ...options,

    onSuccess: (data, variables, context) => {
      cronofySyncEvent({ eventId: variables.actionId, eventType: 'Action', deleted: true });
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteEventAndUpdateBlockAction = (
  options?: Omit<
    UseMutationOptions<DeleteEventResponse, APIError, DeleteEventAndUpdateBlockActionPayload>,
    'onMutate' | 'onSettled'
  >,
) => {
  const updateActionStatus = useUpdateActionStatus();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();

  return useMutation({
    mutationFn: (payload: DeleteEventAndUpdateBlockActionPayload) =>
      fetchData<DeleteEventResponse>(DELETE_EVENT_AND_UPDATE_BLOCK_ACTION, payload),

    onMutate: ({ actionId, blockOrder, blockId, scheduledDate }) => {
      const selectedDate = getSelectedDate();

      if (!selectedDate) {
        return;
      }

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      if (!weeklyPlanId) {
        return;
      }

      queryClient.setQueryData<ActionResponse>(keys.actions.all._ctx.week(weeklyPlanId).queryKey, (state) => {
        if (state == null) {
          return state;
        }

        return {
          action: state.action.map((action) => {
            if (action.id === actionId) {
              return {
                ...action,
                blockId,
                blockOrder,
                scheduledDate,
                scheduledTime: null,
                event: null,
              };
            }

            return action;
          }),
        };
      });
    },

    onSettled: (data) => {
      if (data?.updateActionByPk?.id) {
        updateActionStatus.mutate({
          old: {
            id: data?.updateActionByPk?.id,
            block_id: data?.updateActionByPk?.block?.id,
          },
          new: null,
        });
      }
    },

    ...options,

    onSuccess: (data, variables, context) => {
      cronofySyncEvent({ eventId: variables.actionId, eventType: 'Action', deleted: true });
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCompleteBlockStatus = (
  options?: UseMutationOptions<CompleteBlockResponse, APIError, CompleteBlockPayload>,
) => {
  return useMutation({
    mutationFn: (payload: CompleteBlockPayload) => fetchData<CompleteBlockResponse>(COMPLETE_BLOCK, payload),
    ...options,
  });
};

export const useIncompleteBlockStatus = (
  options?: UseMutationOptions<IncompleteBlockResponse, APIError, IncompleteBlockPayload>,
) => {
  return useMutation({
    mutationFn: (payload: IncompleteBlockPayload) => fetchData<IncompleteBlockResponse>(INCOMPLETE_BLOCK, payload),
    ...options,
  });
};

export const useDeleteBlock = (
  options?: Omit<UseMutationOptions<DeleteBlockResponse, APIError, DeleteBlockPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: DeleteBlockPayload) =>
      fetchData<DeleteBlockResponse>(DELETE_BLOCK, pick(payload, ['blockId'])),

    onMutate: ({ blockId, projectId }: DeleteBlockPayload) => {
      const selectedDate = getSelectedDate();
      const blocks = queryClient.getQueryData<GetBlocksResponse>(keys.blocks.all.queryKey);
      const block = getBlockById(blocks?.block ?? [], blockId);

      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      deleteBlockCache(blockId);

      if (block?.projectId || projectId) {
        deleteBlockFromProjectCache(blockId, block?.projectId ?? projectId!);
      }

      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      block?.categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(block.categoryId).queryKey);

      cacheKeys.forEach((cache) =>
        queryClient.setQueryData<ActionResponse>(cache, (state) => {
          if (state == null) {
            return state;
          }

          return {
            action: state.action.map((action) => {
              if (action.blockId === blockId) {
                return {
                  ...action,
                  blockId: null,
                  blockOrder: null,
                };
              }
              return action;
            }),
          };
        }),
      );
    },

    ...options,
  });
};

export const onMutateBlockStatus = (blockId: string, isCompleted = false) => {
  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      block: state.block.map((block) => {
        if (block.id === blockId) {
          return {
            ...block,
            isCompleted,
          };
        }

        return block;
      }),
    };
  });
};

export const updateBlockInsideCache = ({ id: updatedBlockId, category, categoryId, result, purpose }: Block) => {
  const selectedDate = getSelectedDate();

  if (!selectedDate) {
    return;
  }

  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  if (!weeklyPlanId) {
    return;
  }

  queryClient.setQueryData<ActionResponse>(keys.actions.all._ctx.week(weeklyPlanId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      action: state.action.map((action) => {
        if (action.blockId === updatedBlockId) {
          return {
            ...action,
            categoryId,
            category,
          };
        }
        return action;
      }),
    };
  });

  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      block: state.block.map((block) => {
        if (block.id === updatedBlockId) {
          return {
            ...block,
            result,
            purpose,
            category,
            categoryId,
          };
        }

        return block;
      }),
    };
  });
};

export const completeActionsOfBlock = (blockId: string, categoryId?: string) => {
  const selectedDate = getSelectedDate();
  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  const cacheKeys = [];
  weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
  categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);

  cacheKeys.forEach((cache) =>
    queryClient.setQueryData<ActionResponse>(cache, (state) => {
      if (state == null) {
        return state;
      }

      return {
        ...state,
        action: state.action.map((action) => {
          if (action.blockId === blockId) {
            return {
              ...action,
              progressStatus: 'complete',
            };
          }

          return action;
        }),
      };
    }),
  );
};

export const removeIncompleteActionsFromBlock = (block: Block) => {
  const selectedDate = getSelectedDate();
  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  const cacheKeys = [];
  weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
  block.categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(block.categoryId).queryKey);

  cacheKeys.forEach((cache) =>
    queryClient.setQueryData<ActionResponse>(cache, (state) => {
      if (state == null) {
        return state;
      }

      return {
        ...state,
        action: state.action.map((action) => {
          if (action.blockId === block.id && action.progressStatus !== 'complete') {
            return {
              ...action,
              blockId: null,
              blockOrder: null,
            };
          }

          return action;
        }),
      };
    }),
  );

  if (block.projectId) {
    removeIncompleteActionsFromBlockInProjectCache(block.id, block.projectId);
  }
};

export const deleteEventFromCache = (eventId: string) => {
  const selectedDate = getSelectedDate();

  if (!selectedDate) {
    return;
  }

  const weeklyPlanId = getWeeklyPlanId(selectedDate);

  if (!weeklyPlanId) {
    return;
  }

  queryClient.setQueryData<ActionResponse>(keys.actions.all._ctx.week(weeklyPlanId).queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      action: state.action.map((action) => {
        if (action?.event?.id === eventId) {
          return {
            ...action,
            event: null,
          };
        }

        return action;
      }),
    };
  });
};

export const addActionToCache = (action: Action) => {
  addAction(action);

  if (isPlannedAction(action)) {
    addEventToCache({
      id: action.event.id,
      action: { ...action, typeName: 'Action' },
      actionId: action.id,
      typeName: 'Event',
    });
  }
};
