import { ActionRelation } from '@/constants/action';
import { CATEGORIES_ICONS } from '@/constants/category';
import { useCategoryManager } from '@/services/categories/hooks';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import {
  IconClock,
  IconCommitPersonOutline,
  IconComplete,
  IconFire,
  IconIncompleteDark,
  IconLeveragePersonOutline,
  IconProgress,
  IconStar,
  IconStarFull,
  IconTarget,
} from '@/theme/icons';
import { Action } from '@/types/actions';
import {
  calculateTotalDuration,
  getActionsByBlockId,
  getCompletedActions,
  getDailyActions,
  getIncompleteActions,
  getWeeklyActions,
} from '@/utils/action';
import { formatStringToDate, utcToLocalDate } from '@/utils/calendar';
import { getColorFromToken } from '@/utils/color';
import { getTotalDurationOfStarredActions } from '@/utils/events';
import { fixUncategorizedName, getSecondsFromDuration, humanDuration } from '@/utils/index';
import rem from '@/utils/rem';
import { Box, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useMemo } from 'react';

const darkColor = 'var(--chakra-colors-blue-1000)';
const lightColor = 'var(--chakra-colors-gray-50)';
const grayColor = '#B1BBC833';
const borderColor = '#B1BBC8';

export type PrintableBlockProps = {
  categoryId: string;
  blockId: string;
  view: 'day' | 'week' | string | null;
  date?: string | null;
};

type ActionItemProps = {
  action: Action;
  categoryColor: string;
};

const ActionItem = ({ action, categoryColor }: ActionItemProps) => {
  const completeIcon = {
    incomplete: <IconIncompleteDark width={rem(12)} height={rem(12)} />,
    'in-progress': (
      <Icon
        as={IconProgress}
        sx={{ path: { '&:first-of-type': { fill: 'currentColor' } }, '--progress-background': 'white' }}
        width={rem(12)}
        height={rem(12)}
      />
    ),
    complete: <Icon as={IconComplete} sx={{ path: { stroke: lightColor } }} width={rem(12)} height={rem(12)} />,
  };

  const scheduledDateWithTime =
    action.scheduledDate &&
    utcToLocalDate(action.scheduledDate, action.scheduledTime, action.timezone, action.gmtOffset);
  const date = scheduledDateWithTime && format(scheduledDateWithTime, 'MM/dd/yy');
  const time = action.scheduledTime && scheduledDateWithTime && format(scheduledDateWithTime, 'hh:mm a');

  return (
    <Flex
      sx={{ breakInside: 'avoid' }}
      gap={rem(8)}
      padding={`${rem(6)} ${rem(8)} ${rem(8)} ${rem(8)}`}
      bgGradient={
        action.dateOfStarring
          ? `linear-gradient(90deg, white 0%, ${getColorFromToken(categoryColor, '.10')} 100%)`
          : undefined
      }
      border={`${rem(1)} solid ${darkColor}`}
      borderRadius={rem(4)}
    >
      <VStack paddingY={rem(6)}>{action.progressStatus && completeIcon[action.progressStatus]}</VStack>
      <Box width="full">
        <HStack alignItems="flex-start">
          <Text textStyle="xsmall" paddingY={rem(6)}>
            {action.title}
          </Text>

          <HStack flexShrink={0} marginLeft="auto" paddingY={rem(6)}>
            {action?.promises?.[0] && (
              <HStack
                gap={rem(4)}
                border={`${rem(1)} solid ${borderColor}`}
                borderRadius={rem(4)}
                backgroundColor={lightColor}
                marginY={rem(-6)}
                paddingX={rem(6)}
                paddingY={rem(4)}
              >
                {action?.promises[0]?.kind === ActionRelation.Leverage && (
                  <Icon as={IconLeveragePersonOutline} width={rem(14)} height={rem(14)} />
                )}
                {action?.promises[0]?.kind === ActionRelation.Commit && (
                  <Icon as={IconCommitPersonOutline} width={rem(14)} height={rem(14)} />
                )}
                <Text textStyle="xsmall">{action?.promises[0]?.person.name}</Text>
              </HStack>
            )}

            {date && (
              <Text
                as="div"
                sx={{
                  span: {
                    fontWeight: '900',
                  },
                }}
                textStyle="xsmall"
                border={`${rem(1)} solid ${borderColor}`}
                borderRadius={rem(4)}
                backgroundColor={lightColor}
                marginY={rem(-6)}
                paddingX={rem(6)}
                paddingY={rem(4)}
              >
                <span>{date}</span> {time}
              </Text>
            )}
            <Icon as={IconClock} width={rem(12)} height={rem(12)} />
            <Text textStyle="xsmall">{humanDuration(getSecondsFromDuration(action.duration))}</Text>

            <Icon
              as={action.dateOfStarring ? IconStarFull : IconStar}
              width={rem(14)}
              height={rem(14)}
              color={action.dateOfStarring ? categoryColor : 'currentcolor'}
            />
          </HStack>
        </HStack>
        {action.notes && (
          <Box
            width="full"
            marginTop={rem(2)}
            padding={rem(8)}
            border={`${rem(1)} solid ${getColorFromToken(categoryColor, '1')}`}
            borderRadius={rem(4)}
            backgroundColor={lightColor}
          >
            <Text textStyle="xsmallBlack">Action notes</Text>
            <Text textStyle="xsmall">{action.notes}</Text>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

function PrintableBlock({ categoryId, blockId, view, date }: PrintableBlockProps) {
  const selectedDate = useMemo(() => (date ? formatStringToDate(date) : new Date()), [date]);
  const { data: weeklyPlanId } = useWeeklyPlanId(selectedDate);

  const { data: CMdata } = useCategoryManager({
    categoryId,
  });

  const category = CMdata?.category;
  const block = CMdata?.blocks.find((block) => block.id === blockId);

  const blockActions = useMemo(() => {
    if (!blockId) return;
    const actions = getActionsByBlockId(blockId, CMdata?.actions);

    if (view === 'day') {
      return getDailyActions(actions, selectedDate);
    }

    if (view === 'week') {
      return getWeeklyActions(actions, weeklyPlanId);
    }

    return actions;
  }, [CMdata?.actions, selectedDate, weeklyPlanId, view, blockId]);

  if (!category || !block) {
    return <></>;
  }

  const incompleteActions = getIncompleteActions(blockActions ?? [], 'blockOrder');
  const completedActions = getCompletedActions(blockActions ?? [], 'modifiedAt');

  const totalDuration = humanDuration(calculateTotalDuration(incompleteActions) * 60);
  const starredActionsTotalDuration = getTotalDurationOfStarredActions(incompleteActions);

  return (
    <Box width="full" color={darkColor} backgroundColor={lightColor}>
      <HStack justifyContent="space-between" paddingX={rem(24)} paddingY={rem(16)}>
        <Text textStyle="smallBlack">RPM Block</Text>
        <HStack>
          <Flex
            alignItems="center"
            justifyContent="center"
            width={rem(18)}
            height={rem(18)}
            marginRight={rem(4)}
            borderRadius="full"
            aspectRatio={1}
            backgroundColor={category.color}
          >
            <Icon as={CATEGORIES_ICONS?.[category.icon ?? 'uncategorized']} width={rem(10)} height={rem(10)} />
          </Flex>
          <Text textStyle="smallBlack">{fixUncategorizedName(category.name)}</Text>
        </HStack>
      </HStack>
      <Flex alignItems="stretch" gap={rem(16)} padding={rem(24)} backgroundColor={grayColor}>
        <Flex
          flex="1"
          gap={rem(8)}
          minWidth={0}
          padding={rem(16)}
          paddingLeft={rem(8)}
          borderRadius={rem(4)}
          backgroundColor={lightColor}
        >
          <Icon as={IconTarget} width={rem(14)} height={rem(14)} />

          <Flex flexDirection="column" gap={rem(8)}>
            <Text textStyle="smallBlack">Result</Text>
            <Text textStyle="xsmall">{block.result}</Text>
          </Flex>
        </Flex>
        <Flex
          flex="1"
          gap={rem(8)}
          minWidth={0}
          padding={rem(16)}
          paddingLeft={rem(8)}
          color={darkColor}
          borderRadius={rem(4)}
          backgroundColor={lightColor}
        >
          <Icon as={IconFire} width={rem(14)} height={rem(14)} />

          <Flex flexDirection="column" gap={rem(8)}>
            <Text textStyle="smallBlack">Purpose</Text>
            <Text textStyle="xsmall">{block.purpose}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Box padding={rem(24)}>
        <Flex flexDirection="column" gap={rem(8)}>
          <Text textStyle="smallBlack">Massive Action Plan</Text>

          <Flex alignItems="stretch" gap={rem(8)} marginY={rem(8)}>
            <Flex
              alignItems="center"
              flex="1"
              gap={rem(12)}
              minWidth={0}
              padding={rem(20)}
              bgGradient={`linear-gradient(270deg, ${getColorFromToken(category.color, '0.10')} 0%, ${grayColor} 66.97%)`}
              borderRadius={rem(4)}
            >
              <Icon as={IconStarFull} width={rem(14)} height={rem(14)} color={darkColor} />
              <Text textStyle="small">Starred time</Text>
              <Text textStyle="smallBlack" marginLeft="auto">
                {starredActionsTotalDuration || '0h'}
              </Text>
            </Flex>

            <Flex
              alignItems="center"
              flex="1"
              gap={rem(12)}
              minWidth={0}
              padding={rem(20)}
              borderRadius={rem(4)}
              backgroundColor={grayColor}
            >
              <Icon as={IconClock} width={rem(14)} height={rem(14)} opacity="0.7" />
              <Text textStyle="small">Total time</Text>
              <Text textStyle="smallBlack" marginLeft="auto">
                {totalDuration || '0h'}
              </Text>
            </Flex>
          </Flex>

          {incompleteActions.map((action) => (
            <ActionItem action={action} categoryColor={category.color} key={action.id} />
          ))}

          {completedActions.map((action) => (
            <ActionItem action={action} categoryColor={category.color} key={action.id} />
          ))}
        </Flex>
      </Box>
    </Box>
  );
}

export default PrintableBlock;
