import { ALL_ACTIONS, COMPLETE, UNPLANNED_ACTIONS, UNSCHEDULED_ACTIONS } from '@/constants/action';
import { useCategories } from '@/services/categories/hooks';
import { useActionsBlocksAndEvents } from '@/services/myPlan/hooks';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { useCompleteBlock } from '@/stores/useCompleteBlock';
import { ActionEventType, ActionType, BlockListItemType } from '@/types/myPlan';
import {
  calculateTotalDuration,
  getActionsByBlockId,
  getActionsByCategoryId,
  getCompletedActions,
  getDailyActions,
  getIncompleteActions,
  getWeeklyActions,
} from '@/utils/action';
import { formatDateToString, utcToLocalDate } from '@/utils/calendar';
import { getTotalDurationOfStarredActions } from '@/utils/events';
import { humanDuration, isUncategorized } from '@/utils/index';
import { formatActions, makeBlockItemKey } from '@/utils/myplan';
import { format } from 'date-fns';
import { isEmpty, orderBy } from 'lodash';
import { useCallback, useMemo } from 'react';

const useCaptureListData = (selectedSegmented = ALL_ACTIONS) => {
  const { data } = useActionsBlocksAndEvents();
  const { data: categoriesData } = useCategories();

  const isDailyPlanPage = useIsDailyPlanPage();

  const { selectedDate } = useCalendarMonthlyStore();
  const { data: weeklyPlanId } = useWeeklyPlanId(selectedDate);

  //!TODO: investigate why this is required (it's set in daily/weekly plan where this is used)
  const { blockId: blockToCompleteId } = useCompleteBlock();

  const filterIncompleteActions = (actions: ActionEventType[]) => {
    return actions.filter((action) => action.action.progressStatus !== COMPLETE);
  };

  const filterCompleteActions = (actions: ActionEventType[]) => {
    return actions.filter((action) => action.action.progressStatus === COMPLETE);
  };

  const filterPlannedActions = (actions: ActionEventType[]) => {
    return actions.filter((action) => !!action.action.scheduledDate);
  };

  const filterWeeklyScheduledActions = (actions: ActionEventType[]) => {
    return actions.filter((action) => action.action.scheduledTime !== null);
  };

  const filterWeeklyUnscheduledActions = (actions: ActionEventType[]) => {
    return actions.filter((action) => action.action.scheduledTime === null);
  };

  const filterWeeklyUnplannedActions = useCallback((actions: ActionEventType[]) => {
    return actions.filter((action) => {
      const noDay = action.action.scheduledDate === null;
      return noDay;
    });
  }, []);

  const filterDailyScheduledActions = useCallback(
    (actions: ActionEventType[]) => {
      return actions.filter((action) => {
        if (!action.action.scheduledDate) {
          return false;
        }

        const eventDate = utcToLocalDate(
          action.action.scheduledDate,
          action.action.scheduledTime,
          action.action.timezone,
          action.action.gmtOffset,
        );
        return formatDateToString(eventDate) === formatDateToString(selectedDate);
      });
    },
    [selectedDate],
  );

  const filterDailyUnscheduledActions = useCallback(
    (actions: ActionEventType[]) => {
      return actions.filter((action) => {
        const sameDayWithoutTime =
          action.action.scheduledDate === format(selectedDate, 'yyyy-MM-dd') && action.action.scheduledTime === null;
        return sameDayWithoutTime;
      });
    },
    [selectedDate],
  );

  const formattedBlocks = useMemo<BlockListItemType[]>(() => {
    if (!data) {
      return [];
    }

    const field = isDailyPlanPage ? 'dailyOrder' : 'weeklyOrder';

    const block = orderBy(data.block, field, 'asc');

    return block
      .map((block) => {
        const key = makeBlockItemKey(block.id);

        let blockActions = getActionsByBlockId(block.id, data.action) as ActionType[];

        if (isEmpty(blockActions)) {
          return {
            ...block,
            key,
            totalDuration: '0',
            starredActionsTotalDuration: '0',
          };
        }

        if (isDailyPlanPage) {
          blockActions = getDailyActions(blockActions, selectedDate) as ActionType[];
        } else {
          blockActions = getWeeklyActions(blockActions, weeklyPlanId ?? '') as ActionType[];
        }

        blockActions = blockActions.map((action) => ({
          ...action,
          isLastBlockAction: blockActions.length === 1,
          typeName: 'Action',
        }));

        let allActions = formatActions(blockActions);

        if (selectedSegmented === UNSCHEDULED_ACTIONS) {
          allActions = isDailyPlanPage
            ? filterDailyUnscheduledActions(allActions)
            : filterWeeklyUnscheduledActions(allActions);
        }

        if (selectedSegmented === UNPLANNED_ACTIONS) {
          // can only filter unplanned actions on the weekly page
          allActions = filterWeeklyUnplannedActions(allActions);
        }

        const incompleteActions = filterIncompleteActions(allActions);
        const actions = incompleteActions.map((action) => action.action);
        const totalDuration = humanDuration(calculateTotalDuration(actions) * 60);
        const starredActionsTotalDuration = getTotalDurationOfStarredActions(actions);

        return {
          ...block,
          key,
          actions: allActions.map((action) => action.action),
          totalDuration,
          starredActionsTotalDuration,
        };
      })
      .filter((block) => (block.id === blockToCompleteId ? true : block.actions?.length));
  }, [
    data,
    selectedSegmented,
    selectedDate,
    weeklyPlanId,
    blockToCompleteId,
    isDailyPlanPage,
    filterWeeklyUnplannedActions,
    filterDailyUnscheduledActions,
  ]);

  const plannedActions = useMemo(() => {
    if (!data?.action) {
      return [];
    }
    return filterPlannedActions(formatActions(data.action));
  }, [data?.action]);

  const scheduledActions = useMemo(() => {
    if (!data?.action) {
      return [];
    }
    return isDailyPlanPage
      ? filterDailyScheduledActions(formatActions(data.action))
      : filterWeeklyScheduledActions(formatActions(data.action));
  }, [data?.action, filterDailyScheduledActions, isDailyPlanPage]);

  const filterActionsBySegment = useMemo(() => {
    if (!data) {
      return [];
    }

    let nonBlockActions = data.action
      .filter((action) => action.blockId === null)
      .map((action) => ({
        ...action,
        typeName: 'Action',
      }));

    if (selectedSegmented === UNSCHEDULED_ACTIONS) {
      return isDailyPlanPage
        ? filterDailyUnscheduledActions(formatActions(nonBlockActions))
        : filterWeeklyUnscheduledActions(formatActions(nonBlockActions));
    }

    if (selectedSegmented === UNPLANNED_ACTIONS) {
      // can only filter unplanned actions on the weekly page
      return filterWeeklyUnplannedActions(formatActions(nonBlockActions));
    }

    return isDailyPlanPage
      ? filterDailyScheduledActions(formatActions(nonBlockActions))
      : formatActions(nonBlockActions);
  }, [
    data,
    filterDailyScheduledActions,
    filterDailyUnscheduledActions,
    selectedSegmented,
    isDailyPlanPage,
    filterWeeklyUnplannedActions,
  ]);

  const categories = useMemo(
    () =>
      categoriesData?.category?.map(
        ({ id, name, order, icon, color, role, ultimatePurpose, ultimateVision, secondaryPurposes }) => {
          const actions = getActionsByCategoryId(filterActionsBySegment.map((action) => action.action).flat(), id);
          return {
            id,
            name,
            order,
            icon,
            color,
            role,
            blocks: formattedBlocks.filter((block) => block.categoryId === id),
            incompleteActions: getIncompleteActions(actions),
            completedActions: getCompletedActions(actions, 'modifiedAt'),
            plannedActions: getActionsByCategoryId(
              plannedActions.map((action) => action.action),
              id,
            ),
            scheduledActions: getActionsByCategoryId(
              scheduledActions.map((action) => action.action),
              id,
            ),
            ultimatePurpose,
            ultimateVision,
            secondaryPurposes,
          };
        },
      ),
    [categoriesData?.category, filterActionsBySegment, formattedBlocks, plannedActions, scheduledActions],
  );

  const emptyCategories = useMemo(
    () =>
      (categories || []).filter(
        (c) =>
          !isUncategorized(c) &&
          c.completedActions.length === 0 &&
          c.incompleteActions.length === 0 &&
          c.blocks.length === 0,
      ),
    [categories],
  );

  const notEmptyCategories = useMemo(() => {
    if (selectedSegmented === UNPLANNED_ACTIONS) {
      return (categories || []).filter(
        (c) =>
          isUncategorized(c) ||
          c.completedActions.length > 0 ||
          c.incompleteActions.length > 0 ||
          c.blocks.length > 0 ||
          c.plannedActions.length > 0,
      );
    }
    if (selectedSegmented === UNSCHEDULED_ACTIONS) {
      return (categories || []).filter(
        (c) =>
          isUncategorized(c) ||
          c.completedActions.length > 0 ||
          c.incompleteActions.length > 0 ||
          c.blocks.length > 0 ||
          c.scheduledActions.length > 0,
      );
    }
    return (categories || []).filter(
      (c) =>
        isUncategorized(c) || c.completedActions.length > 0 || c.incompleteActions.length > 0 || c.blocks.length > 0,
    );
  }, [categories, selectedSegmented]);

  return {
    formattedBlocks,
    categories,
    emptyCategories,
    notEmptyCategories,
    filterCompleteActions,
    filterIncompleteActions,
    filterDailyUnscheduledActions,
    filterWeeklyUnplannedActions,
    filterDailyScheduledActions,
  };
};

export default useCaptureListData;
